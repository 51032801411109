import { createMuiTheme } from '@material-ui/core';

const croplyTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#19673A',
      light: '#1F7D47',
      dark: '#0D4123',
    },
    secondary: {
      main: '#FFBD14',
    },
    text: {
      primary: '#333',
      secondary: '#fff',
    },
  },
  typography: {
    fontFamily: "'Circular Std', sans-serif",
  },
});

export default croplyTheme;
