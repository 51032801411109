import React, { useLayoutEffect, Suspense, useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { ROUTES } from './routeList';
import { uuid } from 'utils/uuid';
import Loader from 'common/loader/loader';

const Routes = () => {
  const location = useLocation();

  useLayoutEffect(() => {
    // scroll page to top on route change
    window.scrollTo(0, 0);

    // set tab name
    const route = ROUTES.find((r) => r.path === location.pathname);

    if (route && route.title) {
      document.title = route.title;
    }
  }, [location.pathname]);

  useEffect(() => {
    // remove google autocomplete instances
    document.querySelectorAll('.pac-container').forEach((el) => el.remove());
  }, [location.pathname]);

  return (
    <div>
      <Suspense fallback={<Loader />}>
        <Switch location={location} key={location.pathname}>
          {ROUTES.map(({ path, component }) => (
            <Route key={uuid()} exact path={path} component={component} />
          ))}
          <Route key={uuid()} render={() => <p>Not Found</p>} />
        </Switch>
      </Suspense>
    </div>
  );
};

export default Routes;
