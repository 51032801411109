import React from 'react';

const AboutUs = React.lazy(() => import('pages/aboutUs/aboutUs'));
const HomePage = React.lazy(() => import('pages/homePage/homePage'));
const HowItWorks = React.lazy(() => import('pages/howItWorks/howItWorks'));
const Partners = React.lazy(() => import('pages/partners/partners'));
const ReportsEnterprise = React.lazy(() =>
  import('pages/reportsEnterprise/reportsEnterprise')
);
const Reports = React.lazy(() => import('pages/reports/reports'));
const Pricing = React.lazy(() => import('pages/pricing/pricing'));
const HelpCenter = React.lazy(() => import('pages/helpCenter/helpCenter'));
const HelpCenterTopic = React.lazy(() =>
  import('pages/helpCenter/helpCenterTopic')
);
const HelpCenterQuestion = React.lazy(() =>
  import('pages/helpCenter/helpCenterQuestion')
);
const Product = React.lazy(() => import('pages/product/product'));
const Summary = React.lazy(() => import('pages/summary/summary'));
const Checkout = React.lazy(() => import('pages/checkout/checkout'));
const PaymentSuccess = React.lazy(() =>
  import('pages/paymentSuccess/paymentSuccess')
);
const PaymentFailure = React.lazy(() =>
  import('pages/paymentFailure/paymentFailure')
);
const Privacy = React.lazy(() => import('pages/privacy/privacy'));

export const ROUTES = [
  {
    path: '/',
    component: HomePage,
    title: 'Croply',
    auth: false,
  },
  {
    path: '/how-it-works',
    component: HowItWorks,
    title: 'Croply - How it works',
    auth: false,
  },
  {
    path: '/partners',
    component: Partners,
    title: 'Croply - Partners',
    auth: false,
  },
  {
    path: '/reports-enterprise',
    component: ReportsEnterprise,
    title: 'Croply - Reports Enterprise',
    auth: false,
  },
  {
    path: '/reports',
    component: Reports,
    title: 'Croply - Reports',
    auth: false,
  },
  {
    path: '/pricing',
    component: Pricing,
    title: 'Croply - Pricing',
    auth: false,
  },
  {
    path: '/about-us',
    component: AboutUs,
    title: 'Croply - About Us',
    auth: false,
  },
  {
    path: '/help-centre',
    component: HelpCenter,
    title: 'Croply - Help Center',
    auth: false,
  },
  {
    path: '/help-centre/:helpTopic',
    component: HelpCenterTopic,
    title: 'Croply - Help Center',
    auth: false,
  },
  {
    path: '/help-centre/:helpTopic/:helpQuestion/:questionId',
    component: HelpCenterQuestion,
    title: 'Croply - Help Center',
    auth: false,
  },
  {
    path: '/product',
    component: Product,
    title: 'Croply - Product',
    auth: false,
  },
  {
    path: '/summary',
    component: Summary,
    title: 'Croply - Summary',
    auth: false,
  },
  {
    path: '/checkout',
    component: Checkout,
    title: 'Croply - Checkout',
    auth: false,
  },
  {
    path: '/success',
    component: PaymentSuccess,
    title: 'Croply - Payment Success',
    auth: false,
  },
  {
    path: '/failure',
    component: PaymentFailure,
    title: 'Croply - Payment Failed',
    auth: false,
  },
  {
    path: '/privacy-policy',
    component: Privacy,
    title: 'Croply - Privacy Policy',
    auth: false,
  },
];
