import React from 'react';
import ReactDOM from 'react-dom';
import {
  StylesProvider,
  createGenerateClassName,
  ThemeProvider,
} from '@material-ui/core/styles';
import { BrowserRouter } from 'react-router-dom';
import Routes from 'route/routes';

import * as serviceWorker from './serviceWorker';

import croplyTheme from 'styles/theme';

import 'assets/font/circularStd.otf';
import 'styles/index.scss';

const generateClassName = createGenerateClassName({
  productionPrefix: 'croply',
  disableGlobal: true,
});

ReactDOM.render(
  <React.StrictMode>
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={croplyTheme}>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </ThemeProvider>
    </StylesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
